import { createRouter, createWebHashHistory } from 'vue-router'
import axios from 'axios'
// import version from '@/version.json'
// import HomeView from '../views/HomeView.vue'

const routes = [
    {
        path: '/',
        name: 'Home',
        // component: () => import(/* webpackChunkName: "about" */ '../views/home-dashboard.vue')
        // component: () => import(/* webpackChunkName: "about" */ '../views/Project/ProjectList.vue')
        component: () => import(/* webpackChunkName: "about" */ '../views/projectDashboard.vue')
    },
    {
        path: '/TestDashboard2',
        name: 'TestDashboard2',
        component: () => import(/* webpackChunkName: "about" */ '../views/home-dashboard.vue')
    },
    // {
    //     path: '/TestDashboard',
    //     name: 'TestDashboard',
    //     // component: () => import(/* webpackChunkName: "about" */ '../views/home-dashboard.vue')
    //     component: () => import(/* webpackChunkName: "about" */ '../views/projectDashboard.vue')
    // },
    {
        path: '/Dashboard',
        name: 'Dashboard',
        // component: () => import(/* webpackChunkName: "about" */ '../views/home-dashboard.vue') 
        component: () => import(/* webpackChunkName: "about" */ '../views/projectDashboard.vue')
    },
    {
        path: '/UserProfile',
        name: 'UserProfile',
        component: () => import(/* webpackChunkName: "about" */ '../views/user/UserProfileView.vue')
    },
    {
        path: '/HR/CreateUser',
        name: 'Create UserProfile',
        component: () => import(/* webpackChunkName: "about" */ '../views/user/UserProfileCreate.vue')
    },
    {
        path: '/AC/UserAccessManage/:user_id/:name',
        name: 'User Access Control',
        component: () => import(/* webpackChunkName: "about" */ '../views/AccessControl/UserAccessControlManage.vue')
    },
    {
        path: '/AC/UserAccess',
        name: 'User Access',
        component: () => import(/* webpackChunkName: "about" */ '../views/AccessControl/UserAccessControlView.vue')
    },
    {
        path: '/AC/AccessControlLevel',
        name: 'Access Level Control',
        component: () => import(/* webpackChunkName: "about" */ '@/views/AccessControl/AccessControlView.vue')
    },
    {
        path: '/AC/AccessControlLevelEdit/:id',
        name: 'Manage User Level',
        component: () => import(/* webpackChunkName: "about" */ '@/views/AccessControl/AccessControlEdit.vue')
    },
    {
        path: '/AC/AccessControlLevelCreate',
        name: 'Create User Level',
        component: () => import(/* webpackChunkName: "about" */ '@/views/AccessControl/AccessControlCreate.vue')
    },
    {
        path: '/Login',
        name: 'Login',
        component: () => import(/* webpackChunkName: "about" */ '../views/UserLogin.vue')
    },
    {
        path: '/ViewCompanyProfile',
        name: 'ViewCompanyProfile',
        component: () => import(/* webpackChunkName: "about" */ '../views/company/ViewCompanyProfile.vue')
    },
    {
        path: '/CompanyProfileAction',
        name: 'CompanyProfileAction',
        component: () => import(/* webpackChunkName: "about" */ '../views/company/CompanyProfileAction.vue')
    },
    {
        path: '/Projects',
        name: 'ProjectList',
        component: () => import(/* webpackChunkName: "about" */ '../views/Project/ProjectList.vue'),
        meta: {
            pageDetails: {
                title: 'Projects'
            }
        }
    },
    {
        path: '/Project Overall',
        name: 'ProjectListView',
        component: () => import(/* webpackChunkName: "about" */ '../views/Project/ProjectListViewOnly.vue'),
        meta: {
            pageDetails: {
                title: 'Projects'
            }
        }
    },
    {
        path: '/Projects/Archive',
        name: 'Archive Project List',
        component: () => import(/* webpackChunkName: "about" */ '../views/Project/ArchiveProjectList.vue'),
        meta: {
            pageDetails: {
                title: 'Archive Projects'
            }
        }
    },
    {
        path: '/Customer/View',
        name: 'Customers',
        component: () => import(/* webpackChunkName: "about" */ '../views/customer/customerView.vue')
    },
    {
        path: '/Worker-SubCon',
        name: 'Worker-SubCon',
        component: () => import(/* webpackChunkName: "about" */ '../views/WorkerSubCon/WorkerSubConView.vue')
    },
    {
        path: '/HR/Position',
        name: 'Position',
        component: () => import(/* webpackChunkName: "about" */ '../views/HRM/Position/Index.vue')
    },
    {
        path: '/HR/Employee',
        name: 'Employee',
        component: () => import(/* webpackChunkName: "about" */ '../views/HRM/Employee/Index.vue')
    },
    {
        path: '/HR/Employee/:id',
        name: 'Employee Details',
        component: () => import(/* webpackChunkName: "about" */ '../views/HRM/Employee/Show.vue')
    },
    {
        path: '/HR/Department',
        name: 'Departments',
        component: () => import(/* webpackChunkName: "about" */ '../views/Department/DepartmentView.vue')
    },
    {
        path: '/HR/Department/:department_id',
        name: 'Department Information',
        component: () => import(/* webpackChunkName: "about" */ '../views/Department/DepartmentDetailView.vue')
    },
    {
        path: '/Project/Create',
        name: 'Create Project',
        component: () => import(/* webpackChunkName: "about" */ '../views/Project/CreateProject.vue')
    },
    {
        path: '/Project/Edit/:project_id',
        name: 'Edit Project',
        component: () => import(/* webpackChunkName: "about" */ '../views/Project/ProjectEdit.vue')
    },
    {
        path: '/Project/View/:project_id/:tab?',
        name: 'Project Information',
        component: () => import(/* webpackChunkName: "about" */ '../views/Project/ViewProjectInfo.vue')
    },
    {
        path: '/Project/MobileVerticalMarking/:project_id',
        name: 'Mobile Vertical Marking',
        component: () => import(/* webpackChunkName: "about" */ '../views/Project/MobileVerticalMarking.vue')
    },
    {
        path: '/Project/View/Drawings/:project_id',
        name: 'Drawings',
        component: () => import(/* webpackChunkName: "about" */ '../views/Project/ViewDrawingsByProject.vue')
    },
    {
        path: '/Projects/DeliveryOrders',
        name: 'Delivery Orders',
        component: () => import(/* webpackChunkName: "about" */ '../views/Project/DeliveryOrdersView.vue')
    },
    {
        path: '/Projects/NCR',
        name: 'NCR',
        component: () => import(/* webpackChunkName: "about" */ '../views/Project/NcrView.vue')
    },
    {
        path: '/Project/BatchUpdate/:project_id/:project_name/:type/:id_list',
        name: 'Batch Update',
        component: () => import(/* webpackChunkName: "about" */ '../views/Project/BatchUpdate.vue')
    },
    {
        path: '/Project/ViewProjectBound/:project_id/:site_component_id',
        name: 'View Marking',
        component: () => import(/* webpackChunkName: "about" */ '../views/Project/ViewProjectBound.vue')
    },
    {
        path: '/Project/ViewProjectBoundBySection/:project_id/:section_id/:project_name',
        name: 'View Project Bound By Section',
        component: () => import(/* webpackChunkName: "about" */ '../views/Project/ProjectStatusBySection.vue')
    },
    {
        path: '/CreateSection/:project_id/',
        name: 'Create Section',
        component: () => import(/* webpackChunkName: "about" */ '../views/Section/CreateSection.vue')
    },
    {
        path: '/Project/ViewProjectBoundByCategory/:project_id/:category_id/:project_name',
        name: 'View Project Bound By Category',
        component: () => import(/* webpackChunkName: "about" */ '../views/Project/ProjectStatusByCategory.vue')
    },
    {
        path: '/Mould/MouldList/:project_id?',
        name: 'Mould List',
        component: () => import(/* webpackChunkName: "about" */ '../views/Mould/MouldView.vue')
    },
    {
        path: '/Mould/ProductionPlanning',
        name: 'Production Planning',
        component: () => import(/* webpackChunkName: "about" */ '../views/Mould/ProductionPlanning.vue')
    },
    {
        path: '/Mould/ProductionPlanning/Create',
        name: 'Create Mould Task Planning',
        component: () => import(/* webpackChunkName: "about" */ '../views/Mould/ProductionPlanningCreate.vue')
    },
    {
        path: '/Mould/AssignMarking',
        name: 'Mould Assign Marking',
        component: () => import(/* webpackChunkName: "about" */ '../views/Mould/MouldAssignMarking.vue')
    },
    {
        path: '/Drawings',
        name: 'Drawings List',
        component: () => import(/* webpackChunkName: "about" */ '../views/Drawings/DrawingsView.vue')
    },
    {
        path: '/DrawingsBatchUpdate',
        name: 'Drawings Batch Update',
        component: () => import(/* webpackChunkName: "about" */ '../views/Project/DrawingsBatchUpdate.vue')
    },
    {
        path: '/DrawingsKPI',
        name: 'Drawings KPI',
        component: () => import(/* webpackChunkName: "about" */ '../views/KPI/DrawingsKPI.vue')
    },
    {
        path: '/FileManager/:id?',
        name: 'File Manager',
        component: () => import(/* webpackChunkName: "about" */ '../views/File/FileManager.vue')
    },
    {
        path: '/DrawingsKPI/SetTargets',
        name: 'Set Targets',
        component: () => import(/* webpackChunkName: "about" */ '../views/KPI/DrawingSetDailyTarget.vue')
    },
    {
        path: '/Achievement',
        name: 'Achievement',
        component: () => import(/* webpackChunkName: "about" */ '../views/user/AchievementView.vue')
    },
    {
        path: '/Logs/ViewAll/:id/:project_id',
        name: 'Logs',
        component: () => import(/* webpackChunkName: "about" */ '../views/Log/LogView.vue')
    },
    {
        path: '/WH/Dashboard',
        name: 'Warehouse Dashboard',
        component: () => import(/* webpackChunkName: "about" */ '../views/Warehouse/WarehouseDashboard.vue')
    },
    {
        path: '/WH/Materials',
        name: 'Materials',
        component: () => import(/* webpackChunkName: "about" */ '../views/Warehouse/Material/Index.vue')
    },
    {
        path: '/WH/Material/Create',
        name: 'Create Material',
        component: () => import(/* webpackChunkName: "about" */ '../views/Materials/MaterialCreate.vue')
    },
    {
        path: '/WH/Material/:id',
        name: 'Edit Material',
        component: () => import(/* webpackChunkName: "about" */ '../views/Warehouse/Material/Show.vue')
    },
    {
        path: '/WH/MaterialBom/:id',
        name: 'Material Bom',
        component: () => import(/* webpackChunkName: "about" */ '../views/Materials/MaterialBom.vue')
    },
    {
        path: '/WH/MaterialOrder',
        name: 'Material Order',
        component: () => import(/* webpackChunkName: "about" */ '../views/Warehouse/MaterialOrder/Index.vue')
    },
    {
        path: '/WH/MaterialOrder/:id',
        name: 'Material Order Manage',
        component: () => import(/* webpackChunkName: "about" */ '../views/Warehouse/MaterialOrder/Show.vue')
    },
    {
        path: '/WH/Warehouse',
        name: 'Warehouse',
        component: () => import(/* webpackChunkName: "about" */ '../views/Warehouse/Stock/Index.vue')
    },
    {
        path: '/WH/Bom',
        name: 'Bill Of Materials',
        component: () => import(/* webpackChunkName: "about" */ '../views/Bom/Index.vue')
    },
    // {
    //     path: '/WH/Product/Create',
    //     name: 'Create Product',
    //     component: () => import(/* webpackChunkName: "about" */ '../views/Products/ProductCreate.vue')
    // },
    {
        path: `/WH/Bom/:id`,
        name: 'Edit Bom',
        component: () => import(/* webpackChunkName: "about" */ '../views/Bom/Manage.vue')
    },
    // {
    //     path: '/WH/ProductBom/:id',
    //     name: 'Product Bom',
    //     component: () => import(/* webpackChunkName: "about" */ '../views/Products/ProductBom.vue')
    // },
    {
        path: '/WH/Suppliers',
        name: 'Suppliers',
        component: () => import(/* webpackChunkName: "about" */ '../views/Suppliers/SuppliersList.vue')
    },
    {
        path: '/Settings/ExchangeRate',
        name: 'Exchange Rate',
        component: () => import(/* webpackChunkName: "about" */ '../views/Settings/ExchangeRate.vue')
    },
    {
        path: '/empty',
        name: 'Empty',
        component: () => import(/* webpackChunkName: "about" */ '../views/EmptyPage.vue')
    },
    {
        path: '/sankey',
        name: 'Sankey',
        component: () => import(/* webpackChunkName: "about" */ '../views/SankeyDiagramChartExample.vue')
    },
    {
        path: '/sankey2',
        name: 'Sankey2',
        component: () => import(/* webpackChunkName: "about" */ '../views/Playground/ChartJsSankey.vue')
    },
]

const router = createRouter({
    history: createWebHashHistory(),
    routes
})
async function checkAppNewVersion() {
    const url = `version.json?t=${Date.now()}`
    let res = null
    try {
        res = await axios.get(url)
    }
    catch (err) {
        console.error('checkAppNewVersion error: ', err)
    }
    if (!res) return
    const version = res.data.version
    if (version && version !== localStorage.getItem('version')) {
        localStorage.setItem('version', version)
        console.log('new update')
        window.location.reload()
    }
}
router.beforeEach((to, from, next) => {
    checkAppNewVersion()
    const isAuthenticated = localStorage.getItem('token');
    if (to.name !== 'Login' && !isAuthenticated) {
        next({ name: 'Login' });
    } else if (isAuthenticated && to.name === 'Login') {
        next({ name: 'Dashboard' });
    } else {
        next();
    }
});
export default router

