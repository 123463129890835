<template>
    <v-layout class="set-custom-height">
        <v-main>
            <template v-if="showComponent === true">
                <v-dialog v-model="settingDialog" max-width="600">
                    <v-card>
                        <v-card-item>
                            <span class="font-size-14 text-grey-darken-1 pl-4">Project Shortcut</span>
                            <v-row>
                                <template v-for="item in PROJECT_SHORTCUT" :key="item.id">
                                    <v-col cols="12" md="6">
                                        <v-hover>
                                            <template v-slot:default="{ isHovering, props }">
                                                <v-card v-bind="props" :color="isHovering ? 'primary' : undefined"
                                                    variant="outlined" size="small" class="cursor-pointer border-0"
                                                    @click="projectShortcut(item.id)">
                                                    <v-card-text class="px-4 py-2">
                                                        {{ item.name }}
                                                    </v-card-text>
                                                </v-card>
                                            </template>
                                        </v-hover>
                                    </v-col>
                                </template>

                            </v-row>
                        </v-card-item>
                        <template v-slot:actions>
                            <v-btn class="ms-auto" text="Ok" @click="settingDialog = false"></v-btn>
                        </template>
                    </v-card>
                </v-dialog>
                <v-navigation-drawer v-model="drawer" :temporary="isMobile" :permanent="!isMobile"
                    class="border-0 sidebar-custom-shadow">
                    <template v-if="AVAILABLE_MODULES_DECODED.length > 0">
                        <v-list>
                            <v-list-item :prepend-avatar="PROFILE_AVATAR" :subtitle="USER_EMAIL"
                                :title="USERNAME"></v-list-item>
                        </v-list>
                        <div class="d-block w-100 pt-4"></div>
                        <v-list :lines="false" nav v-for="module in AVAILABLE_MODULES_DECODED" class="px-2 py-1"
                            :key="module.id">
                            <v-list-item @click="setCurrentModule(module.id); module.active = !module.active"
                                :to="module.submodule.length > 0 ? '' : module.link" color="blue-accent-3">
                                <span class="font-size-14">{{ module.name }}</span>
                                <template v-slot:prepend>
                                    <v-icon :icon="getModuleIcon(module.icon)" size="small" color="#ADB4D2"></v-icon>
                                </template>
                                <template v-slot:append v-if="module.submodule.length > 0">
                                    <v-icon :icon="arrowRightIcon" class="module-arrow"
                                        :class="module.active ? 'active' : ''"></v-icon>
                                </template>
                            </v-list-item>

                            <div class="submodule-container" :class="{ 'active': module.active }"
                                v-if="module.submodule.length > 0">
                                <div class="submodule-content">
                                    <template v-for="child in module.submodule" :key="child.id">
                                        <v-list-item @click="setCurrentModule(module.id, child.id)" :to="child.link"
                                            :class="child.id === current_sub_module_id ? 'active-submodule' : ''"
                                            color="blue-accent-3" class="rounded-0">
                                            <span class="font-size-14">{{ child.name }}</span>
                                            <template v-slot:prepend>
                                                <v-icon :icon="getModuleIcon(child.icon)" size="small"
                                                    color="#ADB4D2"></v-icon>
                                            </template>
                                        </v-list-item>
                                    </template>
                                </div>
                            </div>
                        </v-list>
                    </template>
                    <div class="d-flex flex-column" v-else>
                        <v-skeleton-loader type="list-item-avatar-two-line"></v-skeleton-loader>
                        <v-skeleton-loader type="heading"></v-skeleton-loader>
                        <v-skeleton-loader type="heading"></v-skeleton-loader>
                        <v-skeleton-loader type="heading"></v-skeleton-loader>
                    </div>
                </v-navigation-drawer>
                <v-toolbar class="bg-white">
                    <template v-slot:prepend>
                        <v-btn :icon="menuIcon" @click.stop="drawer = !drawer"></v-btn>
                        <v-btn class="d-block d-md-none" :icon="arrowLeftIcon" @click="goBack"></v-btn>
                    </template>
                    <v-btn class="ms-5 text-grey-darken-1 d-none" :icon="noticeIcon">
                    </v-btn>
                    <v-tooltip text="Project Shortcut" location="start">
                        <template v-slot:activator="{ props }">
                            <button id="menu1-activator" class="d-flex align-center" v-bind="props">
                                <img :src="shortcutIcon" style="max-width: 32px; max-height: 32px;">
                            </button>
                        </template>
                    </v-tooltip>
                    <v-menu activator="#menu1-activator">
                        <v-list max-width="250">
                            <v-list-item v-for="item in PROJECT_SHORTCUT" :key="item.id"
                                @click="projectShortcut(item.id)">
                                {{ item.name }}
                            </v-list-item>
                        </v-list>
                    </v-menu>
                    <v-divider class="mx-3 align-self-center" length="24" thickness="2" vertical></v-divider>
                    <div id="menu-activator" class="d-flex align-center h-100 cursor-pointer">
                        <img v-if="COMPANY_LOGO !== 'null'" :src="COMPANY_LOGO" class="h-100 pa-4" :alt="COMPANY_NAME">
                        <span class="font-weight-bold d-none d-md-block mr-3" v-if="!COMPANY_LOGO">
                            {{ COMPANY_NAME || 'Administration' }}
                        </span>
                        <v-icon :icon="arrowDownIcon"></v-icon>
                    </div>
                    <v-menu activator="#menu-activator">
                        <v-dialog max-width="500">
                            <template v-slot:activator="{ props: activatorProps }">
                                <v-list>
                                    <v-list-item v-bind="activatorProps" class="rounded" title="Switch Company">
                                    </v-list-item>
                                    <v-list-item title="Logout" @click="logout" class="rounded"
                                        color="deep-purple-accent-2"></v-list-item>
                                </v-list>
                            </template>

                            <template v-slot:default="{ isActive }">
                                <v-card title="Switch Company">
                                    <v-card-text>
                                        <v-card subtitle="CURRENT SELECTED" :title="COMPANY_NAME" theme="dark"
                                            class="mb-3">
                                            <template v-slot:prepend>
                                                <div class="pa-2 bg-white rounded">
                                                    <v-icon color="deep-purple-accent-2" :icon="companyIcon"></v-icon>
                                                </div>
                                            </template>
                                            <template v-slot:append>
                                                <v-icon color="success" :icon="checkedIcon"></v-icon>
                                            </template>
                                        </v-card>
                                        <template v-if="ALL_COMPANY.length > 1">
                                            <span class="mb-3 d-block font-weight-bold">Companies</span>
                                            <template v-for="own_company in ALL_COMPANY" :key="own_company.id">
                                                <v-list-item :title="own_company.name"
                                                    v-if="COMPANY_NAME !== own_company.name"
                                                    @click="changeCompany(own_company.id)">
                                                </v-list-item>
                                            </template>
                                        </template>
                                        <template v-if="errors">
                                            <v-chip :prepend-icon="alertIcon" class="text-red-accent-4 mt-2">
                                                {{ errors }}
                                            </v-chip>
                                        </template>
                                    </v-card-text>

                                    <v-card-actions>
                                        <v-spacer></v-spacer>

                                        <v-btn text="Close" @click="isActive.value = false"></v-btn>
                                    </v-card-actions>
                                </v-card>
                            </template>
                        </v-dialog>
                    </v-menu>
                </v-toolbar>
            </template>
            <v-container id="set_inner_container_height" :class="showComponent ? '' : 'd-flex'">
                <router-view />
            </v-container>
            <v-footer class="border-t">
                <small class="text-grey">© 2024 - Precast ERP. All rights reserved. (Ver 0.9)</small>
            </v-footer>
        </v-main>
    </v-layout>
</template>

<script>
import {
    mdiChevronDown,
    mdiCheckBold,
    mdiHomeSwitchOutline,
    mdiBellOutline,
    mdiChevronLeft,
    mdiViewDashboardOutline,
    mdiFileDocumentMultipleOutline,
    mdiMenu,
    mdiLogout,
    mdiAlertCircleOutline,
    mdiAccountOutline,
    mdiAccountGroupOutline,
    mdiChartAreaspline,
    mdiCircleSmall,
    mdiAccountDetailsOutline,
    mdiSecurity,
    mdiToyBrickOutline,
    mdiWarehouse,
    mdiFileCabinet,
    mdiFileSign,
    mdiChevronUp,
    mdiChevronRight,
} from '@mdi/js';
import apiService from "@/apiService";
import processErrors from "@/js/ProcessErrors";
export default {
    data() {
        return {
            shortcutIcon: require('@/assets/shortcut_icon1.png'),
            isMobile: false,
            showComponent: false,
            settingDialog: false,
            ALL_COMPANY: null,
            drawer: null,
            errors: null,
            permissions: null,
            AVAILABLE_MODULES_DECODED: [],
            PROJECT_SHORTCUT: null,
            current_module_id: null,
            current_sub_module_id: null,
            USERNAME: localStorage.getItem('username'),
            USER_EMAIL: localStorage.getItem('email'),
            COMPANY_NAME: localStorage.getItem('current_company'),
            PROFILE_AVATAR: localStorage.getItem('profile_photo'),
            COMPANY_LOGO: localStorage.getItem('company_logo'),
            AVAILABLE_MODULES: localStorage.getItem('available_modules'),
            arrowLeftIcon: mdiChevronLeft,
            arrowDownIcon: mdiChevronDown,
            checkedIcon: mdiCheckBold,
            companyIcon: mdiHomeSwitchOutline,
            noticeIcon: mdiBellOutline,
            menuIcon: mdiMenu,
            alertIcon: mdiAlertCircleOutline,
            logoutIcon: mdiLogout,
            arrowUpIcon: mdiChevronUp,
            arrowRightIcon: mdiChevronRight,
        }
    },
    beforeUnmount() {
        window.removeEventListener('resize', this.checkScreenSize); // Remove resize event listener when component is unmounted
    },
    created() {
        document.title = 'Precast ERP';
        this.checkScreenSize();
        window.addEventListener('resize', this.checkScreenSize); // Listen for window resize events
        const token = localStorage.getItem('token');
        if (token) {
            const user_id = localStorage.getItem('user_id');
            if (!user_id) {
                this.logout();
            }
            this.ALL_COMPANY = JSON.parse(localStorage.getItem('available_companies'));
            this.reloadAllModules();
            this.getProjectShortcut();
            console.log("All Modules Loaded");
        }
        this.showComponent = !!token;

        if (!this.showComponent) {
            const element = document.getElementById("app");
            element.style.height = "calc(100% - 36px)"
        }
        // if (this.showComponent) {
        //     this.retrievedPermissionList()
        // }
    },
    methods: {
        async changeCompany($id) {
            try {
                const response = await apiService(`change-company/${$id}`, 'put');
                if (response.status === true) {
                    const company_id = response.data.company.id;
                    const current_company = response.data.company.name;
                    const company_code = response.data.company.company_code;
                    const company_logo = response.data.company.logo;
                    localStorage.setItem('company_id', company_id);
                    localStorage.setItem('current_company', current_company);
                    localStorage.setItem('company_code', company_code);
                    localStorage.setItem('company_logo', company_logo);
                    location.reload()
                } else {
                    this.error = 'Something when wrong';
                }
            } catch (error) {
                this.errors = error.message;
            }
            // finally {
            //     this.loading = false;
            // }
        },
        async reloadAllModules() {
            try {
                const response = await apiService(`modules`, 'get');
                if (response.status === false) {
                    processErrors()
                } else {
                    const modules = response.data.modules
                    const module_list = []
                    modules.forEach(element => {
                        const module = {
                            id: element.id,
                            name: element.name,
                            icon: element.icon,
                            link: element.link,
                            submodule: element.submodule,
                            active: false
                        }
                        module_list.push(module)
                    });
                    this.AVAILABLE_MODULES_DECODED = module_list
                }
            } catch (error) {
                processErrors()
            }
        },
        async getProjectShortcut() {
            try {
                const response = await apiService(`projects/min`, 'get');
                if (response.status === false) {
                    processErrors(response)
                } else {
                    this.PROJECT_SHORTCUT = response.data.projects
                }
            } catch (error) {
                processErrors(error)
            }
        },
        logout() {
            // Clear local storage
            localStorage.clear();

            // Optionally, you might want to redirect the user to the login page
            // You can use Vue Router for this:
            location.reload();
        },
        getModuleIcon(val) {
            switch (val) {
                case 'dashboard_icon':
                    return mdiViewDashboardOutline
                case 'kpi_icon':
                    return mdiChartAreaspline
                case 'project_icon':
                    return mdiFileDocumentMultipleOutline
                case 'customer_icon':
                    return mdiAccountDetailsOutline
                case 'hr_icon':
                    return mdiAccountGroupOutline
                case 'user_profile_icon':
                    return mdiAccountOutline
                case 'access_icon':
                    return mdiSecurity
                case 'mould_icon':
                    return mdiToyBrickOutline
                case 'warehouse_icon':
                    return mdiWarehouse
                case 'file_icon':
                    return mdiFileCabinet
                case 'contract_icon':
                    return mdiFileSign
                default:
                    return mdiCircleSmall
            }
        },
        setCurrentModule(module_id, sub_module_id = 0) {
            localStorage.setItem('module_id', module_id)
            this.current_module_id = module_id
            if (sub_module_id > 0) {
                localStorage.setItem('sub_module_id', sub_module_id)
                this.current_sub_module_id = sub_module_id
            } else {
                localStorage.removeItem('sub_module_id')
                this.current_sub_module_id = null
            }
        },
        checkScreenSize() {
            this.isMobile = window.innerWidth < 768; // Set isMobile to true if screen width is less than 768 pixels
        },
        goBack() {
            this.$router.go(-1); // Go back to the previous page
        },
        projectShortcut(id) {
            // const pathName = this.$route.name;
            // const fixedPathNamne = 'Project Information'
            const newPath = `/Project/View/${id}`;

            // if (pathName !== fixedPathNamne) {
            this.$router.push(newPath);
            // } else {
            //     // 如果路径相同，强制重新加载组件
            //     this.$router.replace({ path: '/empty', query: { redirect: newPath } })
            //         .then(() => {
            //             this.$router.push(newPath);
            //         });
            // }
            // this.settingDialog = false
        }
    }
}
</script>

<style lang="scss">
@import url('https://fonts.googleapis.com/css2?family=Inter:ital,opsz,wght@0,14..32,100..900;1,14..32,100..900&display=swap');

body {
    font-family: 'Inter', sans-serif;
}

::-webkit-scrollbar {
    width: 8px;
    /* Width of the scrollbar */
    height: 8px;
}

::-webkit-scrollbar-thumb {
    background-color: #d1d1d1;
    /* Color of the scrollbar thumb */
    border-radius: 6px;
    /* Rounded corners */
}

::-webkit-scrollbar-track {
    background-color: white;
    /* Color of the scrollbar track */
}

body,
html {
    // background-color: #f9fafb;
    background-color: #fafafa;
    // background-color: white;
    height: 100%;
}

#app {
    height: calc(100% - 100px);
}

.set-custom-height {
    height: 100%;
}

#set_inner_container_height {
    min-height: 100%;
}

.v-breadcrumbs-item {
    padding: 0 !important;
}

.custom-table-block {
    padding: 1rem;
    border-radius: 0.375rem;
    background-color: white;
}

.text-type-main {
    font-size: 16px;
    font-weight: 500;
}

.text-type-sub {
    font-size: 14px;

    &-mini {
        font-size: 12px;
    }
}

.light-grey-border {
    border: 1px solid #e9ecef;
}

.bg-light-grey {
    background-color: #e9ecef;
}

.profile-avatar {
    height: 40px;
    width: 40px;
}

.swal2-container {
    z-index: 9999
}

// ::-webkit-input-placeholder {
//     /* WebKit browsers */
//     color: transparent !important;
// }

// :-moz-placeholder {
//     /* Mozilla Firefox 4 to 18 */
//     color: transparent !important;
// }

// ::-moz-placeholder {
//     /* Mozilla Firefox 19+ */
//     color: transparent !important;
// }

// :-ms-input-placeholder {
//     /* Internet Explorer 10+ */
//     color: transparent !important;
// }

// input::placeholder {
//     color: transparent !important;
// }

// textarea::-webkit-input-placeholder {
//     /* WebKit browsers */
//     color: transparent !important;
// }

// textarea:-moz-placeholder {
//     /* Mozilla Firefox 4 to 18 */
//     color: transparent !important;
// }

// textarea::-moz-placeholder {
//     /* Mozilla Firefox 19+ */
//     color: transparent !important;
// }

// textarea:-ms-input-placeholder {
//     /* Internet Explorer 10+ */
//     color: transparent !important;
// }

// textarea::placeholder {
//     color: transparent !important;
// }

.swal2-confirm.swal2-styled,
.swal2-cancel.swal2-styled,
.swal2-confirm.swal2-styled.swal2-default-outline {
    color: white !important;
}

.swal2-html-container ul {
    list-style-type: none;
}

.dp__pointer.dp__input.dp__input_icon_pad.dp__input_reg {
    border: 1px solid #BDBDBD;
}

.dp__pointer.dp__input.dp__input_icon_pad.dp__input_reg:hover {
    border: 1px solid black;
}

.dp__disabled.dp__input_readonly.dp__input.dp__input_icon_pad.dp__input_reg:hover,
.dp__disabled.dp__input_readonly.dp__input.dp__input_icon_pad.dp__input_reg:disabled {
    border: 1px solid #E0E0E0;
    color: #BDBDBD;
    background-color: white;
}

.sidebar-custom-shadow {
    box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 3px 0px, rgba(0, 0, 0, 0.06) 0px 1px 2px 0px;

    .v-list-group__items {
        background-color: #f9f9f9;
        border-radius: 0.375rem;
        padding-top: 0.5rem;
        padding-bottom: 0.5rem;
    }
}

.font-size {

    &-11 {
        font-size: 11px;
    }

    &-12 {
        font-size: 12px;
    }

    &-13 {
        font-size: 13px;
    }

    &-14 {
        font-size: 14px;
    }

    &-16 {
        font-size: 16px;
    }

    &-18 {
        font-size: 18px;
    }

    &-20 {
        font-size: 20px;
    }

    &-22 {
        font-size: 22px;
    }

    &-24 {
        font-size: 24px;
    }

    &-30 {
        font-size: 30px;
    }

    &-32 {
        font-size: 32px;
    }
}

.mobile-only {
    display: none;
}

.submodule-container {
    overflow: hidden;
    max-height: 0;
    transition: max-height 0.2s ease-out;

    &.active {
        max-height: 1000px; // 设置一个足够大的值
        transition: max-height 0.4s ease-in;
    }

    .submodule-content {
        border-radius: 0.375rem;
        background-color: #f9f9f9;
        padding: 0.5rem 0;
    }
}

.module-arrow {
    transition: transform 0.2s ease;
    transform: rotate(0deg);

    &.active {
        transform: rotate(90deg);
    }
}

.active-module,
.active-submodule {
    color: #2979FF;
    background-color: #dfe9f9;
}

.custom-selector-item-with-dropdown {
    height: 36px;
    min-width: 200px;
    width: 200px;
    background-color: white;
    --my-shadow: 0px 0px 0px 1px rgba(9, 9, 11, .07), 0px 2px 2px 0px rgba(9, 9, 11, .05);
    --my-shadow-colored: 0px 0px 0px 1px var(--my-shadow-color), 0px 2px 2px 0px var(--my-shadow-color);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--my-shadow);

    .dropdown-icon {
        margin-left: auto;
    }
}

.custom-input-wrapper,
.custom-search-bar {
    width: 200px;

    &.long-type {
        width: 350px;

        .v-field__append-inner {
            padding-top: 0;
            align-items: center;
        }
    }
}

.mobile-material-btn {
    position: fixed;
    bottom: 16px;
    right: 16px;
    z-index: 1;
}

.sw-custom-border {
    --my-shadow: 0px 0px 0px 1px rgba(9, 9, 11, .07), 0px 2px 2px 0px rgba(9, 9, 11, .05);
    --my-shadow-colored: 0px 0px 0px 1px var(--my-shadow-color), 0px 2px 2px 0px var(--my-shadow-color);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--my-shadow);
}

@media only screen and (max-width: 767.5px) {

    .custom-input-wrapper,
    .custom-search-bar {
        width: 100%;

        &.long-type {
            width: 100%;
        }
    }

    .custom-selector-item-with-dropdown {
        min-width: 100%;
        width: 100%;
    }

    .mobile-only {
        display: flex;
    }

    .desktop-only {
        display: none;
    }
}
</style>