import Swal from "sweetalert2";

function processErrors(response) {
    if (response.data.length === 0) {
        Swal.fire({
            icon: "error",
            title: "Oops...",
            text: response.message,
        });
        return false
    } else {
        let object = {};
        let title = 'Oops...'
        const errorLog = response.data.errors;
        let msgList = '';
        if (response.message) {
            title = response.message
        }
        if (response.data.length > 0) {
            for (const [key, value] of Object.entries(errorLog)) {
                if (Array.isArray(value)) {
                    object[key] = value[0]
                    // msgList += `<li>${value[0]}</li>`;
                } else {
                    for (const [key2, value2] of Object.entries(value)) {
                        object[key2] = value2
                    }
                }
                msgList += `<li>${value.toString()}</li>`;
            }
        } else if (response.data.errors.length > 0) {
            object = response.data.errors
            msgList = `<li>${response.message}</li>`;
        } else {
            if (errorLog) {
                for (const [key, value] of Object.entries(errorLog)) {
                    if (Array.isArray(value)) {
                        object[key] = value[0]
                        // msgList += `<li>${value[0]}</li>`;
                    } else {
                        for (const [key2, value2] of Object.entries(value)) {
                            object[key2] = value2
                        }
                    }
                    msgList += `<li>${value.toString()}</li>`;
                }
            } else {
                msgList = `<li>${response.message}</li>`;
            }
        }

        Swal.fire({
            icon: "error",
            title: title,
            html: `<ul>${msgList}</ul>`,
        });
        return object
    }
}

export default processErrors